
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  components: {},

  props: {
    form: {
      type: Object,
      required: true
    },
    currentErrors: {
      type: Object,
      default: () => {}
    }
  },

  emits: [
    'update:form'
  ],

  setup (props, { emit }) {
    const profile = ref<any>({
      autoSchedule: props.form.autoSchedule,
    })

    watch(profile, (newValue) => {
      emit('update:form', newValue)
    }, { deep: true })

    return {
      profile
    }
  }
})
