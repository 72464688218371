<template>
  <div class="card" id="auto-schedule-session">
    <div class="card-header">
      <div>
        <div class="card-title">{{ $t('page.settings.profile_auto_schedule.title') }}</div>
        <small>{{ $t('page.settings.profile_auto_schedule.description') }}</small>
      </div>
    </div>

    <div class="card-body pb-4">
      <label class="flex items-center space-x-2">
        <input
          class="border-gray-300 rounded cursor-pointer"
          type="checkbox"
          v-model="profile.autoSchedule"
        />
        <span
          class="text-sm"
          v-html="$t('form.settings.profile_auto_schedule.enable_content_auto_schedule-html')"
        />
      </label>
      <div
        class="bg-blue-100 rounded p-4 text-sm mt-4"
        v-html="$t('page.settings.profile_auto_schedule.instructions')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  components: {},

  props: {
    form: {
      type: Object,
      required: true
    },
    currentErrors: {
      type: Object,
      default: () => {}
    }
  },

  emits: [
    'update:form'
  ],

  setup (props, { emit }) {
    const profile = ref<any>({
      autoSchedule: props.form.autoSchedule,
    })

    watch(profile, (newValue) => {
      emit('update:form', newValue)
    }, { deep: true })

    return {
      profile
    }
  }
})
</script>
